<template>
  <div id="dividend-list"></div>
  <a v-if="props.showLink" class="link" href="./calender.php"
    >Till utdelningar</a
  >
</template>

<script>
import { onMounted, ref } from "vue";
import { token } from "@/utilities/token";
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    showLink: {
      type: Boolean,
      default: false,
    },
    maxrows: {
      type: Number,
    },
    pagination: {
      type: Number,
      default: 0,
    },
    fields: {
      type: Array,
      required: true,
    },
    onReadyCallback: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    MillistreamWidgetSettings.language = "sv";
    MillistreamWidgetSettings.token = token;
    const buildWidgets = () => {
      new Milli_Events({
        target: document.getElementById("dividend-list"),
        list: props.list,
        language: "sv",
        showzerodividend: true,
        pagination: props.pagination,
        maxrows: props.maxrows,
        // href: "detailed.php?",
        link_fields: ["insref"],
        fields: props.fields,
        onreadyCallback: props.onReadyCallback,
        underlyingid: true,
        onrowclick: function (e) {
          document.location.href = "detailed.php?insref=" + e.insref;
        },
      });
    };
    onMounted(() => {
      buildWidgets();
    });
    return { props };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";

@media (min-width: 781px) {
}
@media (max-width: 781px) {
}
</style>
