<template>
  <div v-if="props.searchCompare" :id="props.targetId"></div>
  <div
    class="flex-row white-box box-container"
    id="search-container"
    v-else
    v-on:keypress.enter="searchOnEnterClick()"
  >
    <div :id="props.targetId"></div>
    <button
      class="round-button"
      id="search-button"
      :disabled="buttonDisabled"
      @click="searchButtonFunc()"
    >
      Sök
    </button>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { searchInsrefs } from "@/utilities/SearchHelper.js";
import { searchOnClickFunction } from "@/utilities/SearchHelper.js";
import { token } from "@/utilities/token";
export default {
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    targetId: {
      type: String,
      required: true,
    },
    onClickFunction: {
      type: Function,
      required: true,
    },
    marketplaces: {
      type: Array,
    },
    searchCompare: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    let search;
    const buttonDisabled = ref(true);
    const buildWidget = () => {
      MillistreamWidgetSettings.language = "sv";
      MillistreamWidgetSettings.token = token;
      search = new Milli_Search({
        target: document.getElementById(props.targetId),
        fields: ["marketplaceflag", "name"],
        marketplace: props.marketplaces ? props.marketplaces : null,
        list: props.marketplaces ? null : searchInsrefs,
        placeholder: props.placeholder,
        order: ["asc", "asc"],
        orderby: ["instrumenttype", "name"],
        instrumenttype: [4, 8, 9],
        link_field: ["instrumenttype", "insref", "name", "marketplace"],
        searchfields: ["name", "symbol", "isin", "issuer"],
        maxrows: 10,
        triggerlimit: 2,
        headerinterval: "instrumenttype",
        closeOnBlur: true,
        typeheaders: {
          4: {
            name: "Aktier",
          },
          // 6: {
          //     name: "Index",
          // },
          8: {
            name: "Fonder",
          },
          9: {
            name: "Teckningsrätter",
          },
        },
        onclick: function (e) {
          props.searchCompare
            ? props.onClickFunction(e, "search")
            : searchOnClickFunction(e);
        },
        onjsonResultCallback: (res) => {
          if (res && res.length > 0) buttonDisabled.value = false;
          else buttonDisabled.value = true;
        },
        onBlurCallback: function (search, event) {
          if (
            !event.relatedTarget &&
            event.relatedTarget?.id !== "search-button"
          )
            buttonDisabled.value = true;
        },
      });
    };
    const searchOnEnterClick = () => {
      if (document.querySelector(".millistream-search-selected")) return;
      const searchContainer = document.getElementById("search-container");
      const searchValue = searchContainer.querySelector(
        ".millistream-search-input"
      ).value;
      location.href = `searchresult.php?searchText=${searchValue}`;
    };
    const searchButtonFunc = () => {
      const text = search.getLastSearch();
      location.href = `searchresult.php?searchText=${text}`;
    };
    onMounted(() => {
      buildWidget();
    });

    return { props, buttonDisabled, searchButtonFunc, searchOnEnterClick };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
#search-div {
  width: 100%;
}
#search-div,
#search-compare {
  position: relative;
  input {
    background: $white;
    width: 100%;
    border: thin solid rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    border-radius: 4px;
    flex-grow: 1;
    font-family: SuecaSans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 0.5rem 1rem;
    color: #888888;
    transition: border-color 300ms;
    &:hover {
      border-color: rgba(0, 0, 0, 0.32);
    }
  }
  .millistream-search-results-div {
    z-index: 100;
    position: absolute;
    box-shadow: $dropdown-shadow;
    width: 100%;
    border: thin solid rgba(0, 0, 0, 0.16);
    border-top: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  table.millistream-search-table {
    width: 100%;
    background: $white;
    font-family: SuecaSans;
    font-style: normal;
    line-height: 18px;
    font-size: 15px;
    color: #333333;
    font-weight: 600;
    border-spacing: 0;
    // padding: 0 0.5rem;
    tbody {
      display: block;
      tr {
        display: flex;
        width: 100%;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
        padding: 0.5rem;
        justify-items: start;
        text-align: left;
        &:first-child {
          padding-bottom: 0;
        }
        &.millistream-search-selected {
          background-color: $lightgray;
          td {
            span {
              border-color: #333333;
            }
          }
        }
        &:hover {
          cursor: pointer;
          td {
            span {
              border-color: #333333;
            }
          }
        }
        td {
          &.millistream-type-header-td {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: rgba(37, 45, 64, 0.5);
          }
          &.millistream-flag-se {
            min-height: 14px;
            min-width: 14px;
            background: url("~@/assets/images/14/sverige.png");
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.millistream-flag-dk {
            min-height: 14px;
            min-width: 14px;
            background: url("~@/assets/images/14/danmark.png");
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.millistream-flag-no {
            min-height: 14px;
            min-width: 14px;
            background: url("~@/assets/images/14/norge.png");
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.millistream-flag-fi {
            min-height: 14px;
            min-width: 14px;
            background: url("~@/assets/images/14/finland.png");
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.millistream-flag-us {
            min-height: 14px;
            min-width: 14px;
            background: url("~@/assets/images/14/usa.png");
            background-size: contain;
            background-repeat: no-repeat;
          }
          span {
            border-bottom: thin solid transparent;
            transition: border-color 300ms;
          }
        }
      }
    }
  }
}

@media (min-width: 781px) {
}
@media (max-width: 781px) {
  #search-compare {
    width: 100%;
  }
  #search-div {
    input.millistream-search-input {
      padding: 0.5rem;
    }
    td {
      &:nth-child(2) {
        & span {
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 10rem;
        }
      }
    }
  }
}
</style>
