<template>
  <div :id="props.targetId"></div>
</template>

<script>
import { onMounted, ref } from "vue";
import { token } from "@/utilities/token";
export default {
  props: {
    instrument: {
      type: Array,
      defualt: null,
    },
    fields: {
      type: Array,
      required: true,
    },
    targetId: {
      type: String,
      required: true,
    },
    decimals: {
      type: Number,
      default: 0,
    },
    custom_lang: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props) {
    MillistreamWidgetSettings.language = "sv";
    MillistreamWidgetSettings.token = token;
    const buildWidgets = () => {
      new Milli_Facts({
        target: document.getElementById(props.targetId),
        instrument: props.instrument,
        fields: props.fields,
        num_decimals: props.decimals,
        link_url: true,
        shortnumbers: true,
        onreadyCallback: function () {},
      });
    };
    onMounted(() => {
      buildWidgets();
    });
    return { props };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";

@media (min-width: 781px) {
}
@media (max-width: 781px) {
}
</style>
