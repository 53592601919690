<template>
  <div v-show="showNews" class="flex-column" :id="props.parentId">
    <h3>{{ props.headerText }}</h3>
    <div :id="props.divId"></div>
    <div v-if="props.link && props.linkText" id="more-news">
      <a class="link" :href="props.link">{{ props.linkText }}</a>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, ref } from "vue";
import { token } from "@/utilities/token";
export default {
  components: {},
  props: {
    agency: {
      type: Array,
      default: null,
    },
    headerText: {
      type: String,
    },
    instrument: {
      type: Array,
      default: null,
    },
    divId: {
      type: String,
      required: true,
    },
    link: {
      type: String,
    },
    linkText: {
      type: String,
    },
    maxRows: {
      type: Number,
      default: 10,
    },
    fields: {
      type: Array,
      required: true,
    },
    parentId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const showNews = ref(false);
    const buildWidgets = () => {
      MillistreamWidgetSettings.language = "sv";
      MillistreamWidgetSettings.token = token;
      let news = new Milli_Newsheadlines({
        target: document.getElementById(props.divId),
        agency: props.agency,
        instrument: props.instrument,
        noheader: true,
        maxrows: props.maxRows,
        fields: props.fields,
        timeformat: "HH:mm",
        newslanguage: props.agency.includes(8232) ? ["en"] : ["sv"],
        link_field: ["newsid", "insref", "url"],
        // href: "newsdetailed.php?",
        onclick: function (e) {
          if (e.insref === 8223 && e.url) location.href = e.url;
          else
            location.href =
              "newsdetailed.php?newsid=" + e.newsid + "&insref=" + e.insref;
        },
        onDraw: function (e) {
          if (e.headlines.length > 0) showNews.value = true;
        },
      });
    };
    onMounted(() => {
      buildWidgets();
    });

    return { props, showNews };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
#more-news {
  padding: 0 0 1rem 0;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
}

@media (min-width: 781px) {
}
@media (max-width: 781px) {
}
</style>
