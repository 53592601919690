// export const token =
//   process.env.NODE_ENV === "production"
//     ? svdToken
//     : "b8500098-c785-4b4f-bc0a-4a48e7f65950";

export const token =
  process.env.NODE_ENV === "production"
    ? svdToken
    : "b8500098-c785-4b4f-bc0a-4a48e7f65950";

// MillistreamWidgetSettings.language = "sv";
// MillistreamWidgetSettings.token =
//   process.env.NODE_ENV === "production" ? svdToken : token;
